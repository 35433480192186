<template>
  <div class="header">
    <div 
      @click="backToMenu()"    
      class="btn-img">
      <img src="@/assets/icon-menu.png"/>
    </div>
  </div>
  <div class="page-title">Select step</div>
  <div class="steps">
    <div v-for="step in steps" :key="step.stepId" class="step">
      <div class="btn-step-group" :class="{disabled: step.forms.length < 1}">
        <input type="radio" name="step" 
          :disabled="step.forms.length < 1"
          :value="step.stepId" v-model="selectedStep" :id="`step_`+step.stepId"/>
        <span class="step-radio"></span>
        <label :for="`step_`+step.stepId" class="btn-step">
          {{ step.stepName }}
        </label>
      </div>
      <div v-if="step.forms?.length > 0 && selectedStep==step.stepId">
        <checked-list-item
          :lists="selectedFormList" @changed="onChangeSelectForms" class="form-list"/>
      </div>
    </div>
  </div>
  <div class="btns-in-row mt-2">
    <div
      @click="back"
      class="btn-img">
      <img src="@/assets/icon-back.png"/>
    </div>
    <button
      @click="next"
      :disabled="selectedStep == null || selectedForms.length < 1"
      class="btn-img">
      <img src="@/assets/icon-next.png"/>
    </button>
  </div>
</template>

<script>
import CheckedListItem from '../../components/atom/CheckedListItem.vue'
export default {
  name: 'SelectStep',
  components: {
    CheckedListItem,
  },
  data() {
    return {
      steps: [],
      redirectTo: null,
      selectedStep: null,
      selectedForms: [],
      selectedFormList: [],
    }
  },
  created() {
    this.$api.get('/steps').then(res => {
      this.steps = res.data.steps
    })
  },
  methods: {
    onChangeSelectForms(selected) {
      this.selectedForms = selected
    },
    backToMenu() {
      this.$router.push('menu')
    },
    back() {
      this.$router.back()
    },
    next() {
      const nextPath = this.$route.query.redirectTo
      console.log('nextPath:' + nextPath)
      const paramChar = nextPath.includes('?') ? "&" : "?"
      console.log('paramChar:' + paramChar)
      this.$router.push(`${nextPath}${paramChar}step=${this.selectedStep}&forms=${this.selectedForms.join(',')}`)
    },
  },
  watch: {
    selectedStep(newValue, oldValue) {
      if (newValue != oldValue) {
        const selectedStep = this.steps.find(s => s.stepId == newValue)
        this.selectedFormList = selectedStep.forms.map(f => {
          return {
            id: f.formId,
            label: f.formName,
          }
        })
        this.selectedForms = []
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.steps {
  display: flex;
  justify-content: space-evenly;
  width: 90%;
  margin: auto;
  margin-top: 50px;
  height: 300px;
}
.btn-step-group {
  display: flex;
  align-items: center;
}
.disabled {
  opacity: 0.5;
}
.btn-step {
  background: linear-gradient(to top, #0E1D3A, #28529D);
  color: white;
  font-size: 30px;
  padding: 5px 10px;
  width: 200px;
  border-radius: 10px;
  box-shadow: 3px 2px 2px #d2d9e8;
  &:hover {
    cursor: pointer;
  }
}
input[type=radio] {
  display: none;
}
input:checked + .step-radio:after {
  transform: scale(1);
}
.step-radio {
  height: 30px;
  width: 30px;
  background: #96b4e4;
  // border: 2px solid #28529D;
  cursor: pointer;
  margin-right: 10px;
  border-radius: 50%;
  position: relative;

  &:after {
    content: '';
    position: absolute;
    top: 0px;
    left: 0px;
    height: 10px;
    width: 10px;
    background: white;
    border: 10px solid #28529D;
    border-radius: 50%;
    transform: scale(0);
    transition: all 0.1s;
  }
}
.form-list {
  width: 150px;
  margin: auto;
  margin-top: 20px;
}
</style>