<template>
  <div class="lists">
    <label v-for="list in lists"
      class="list"
      :class="{active : selected.includes(list.id)}"
      :for="`list_`+list.id"
      :key="list.id">
      <span>{{ list.label }}</span>
      <input
        @click="onChange(list)"
        type="checkbox" :name="`list_`+list.id" :id="`list_`+list.id">
    </label>
  </div>
</template>

<script>
export default {
  props: {
    lists: {
      type: Array,
      require: true,
    },
  },
  data() {
    return {
      selected: [],
    }
  },
  methods: {
    onChange(list) {
      if (this.selected.includes(list.id)) {
        this.selected = this.selected.filter(s => s != list.id)
      } else {
        this.selected.push(list.id)
      }
      this.$emit('changed', this.selected)
    },
  },
}
</script>

<style lang="scss" scoped>
.lists {
  border: 1px solid rgb(219, 203, 203);
  border-radius: 5px;
  box-shadow: 3px 6px 6px 1px rgb(80 80 80 / 20%);
  .list:last-child {
    border-bottom: none;
  }
  .list {
    background: none;
    position: relative;
    border: none;
    padding: 10px 10px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    overflow: hidden;
    transition-duration: 0.4s;
    font-size: 18px;
    border-bottom: 1px solid rgb(237, 223, 223);
    &:hover {
      background: rgba(190, 177, 177, 0.2);
      opacity: 0.6;
    }
    span {
      width: 70%;
    }
  }
  .active, .active:hover {
    // background: #8aaff0;
    background: #f3e9e9;
    opacity: 1;
    // color: white;
  }
  .list:after {
    content: "";
    background: #6b4597;
    display: block;
    position: absolute;
    padding-top: 300%;
    padding-left: 100%;
    margin-left: -10px!important;
    margin-top: -120%;
    opacity: 0;
    // transition: all 0.2s;
  }
  .list:active:after {
    padding: 0;
    margin: 0;
    opacity: 1;
    transition: 0s;
  }
}
</style>